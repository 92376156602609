/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { DataSourceControllerService } from './services/DataSourceControllerService';
import { DummyRestControllerService } from './services/DummyRestControllerService';
import { HazardWarningControllerService } from './services/HazardWarningControllerService';
import { MapLayerControllerService } from './services/MapLayerControllerService';
import { ProxyControllerService } from './services/ProxyControllerService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class ViewerApiClientV2 {

    public readonly dataSourceController: DataSourceControllerService;
    public readonly dummyRestController: DummyRestControllerService;
    public readonly hazardWarningController: HazardWarningControllerService;
    public readonly mapLayerController: MapLayerControllerService;
    public readonly proxyController: ProxyControllerService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '/api/v2',
            VERSION: config?.VERSION ?? '0.0.1',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.dataSourceController = new DataSourceControllerService(this.request);
        this.dummyRestController = new DummyRestControllerService(this.request);
        this.hazardWarningController = new HazardWarningControllerService(this.request);
        this.mapLayerController = new MapLayerControllerService(this.request);
        this.proxyController = new ProxyControllerService(this.request);
    }
}

