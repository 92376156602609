import {keycloakService} from '@ndw/react-keycloak-authentication';
import {FeatureCollection} from 'geojson';
import isDefinedType from 'is-defined-type';
import {combineEpics, Epic, StateObservable} from 'redux-observable';
import {EMPTY, of} from 'rxjs';
import {filter, flatMap, map, mergeMap} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {LayerDTO} from '../../../generated/ViewerApiClient';
import {SearchFeature} from '../../../interfaces/SearchFeature';
import {HttpSnapshot, StreamableSnapshot} from '../../../models';
import {RootState} from '../../../reducer';
import {
    mapSceneDataClearFilteredDataForDataSource,
    mapSceneDataClearMapFilteredData,
    mapSceneDataLoadDataSource,
    mapSceneDataSourceUpdateMissed,
    mapSceneDataUpdateBaseDataSource,
    mapSceneDataUpdateDataSource,
    mapSceneDataUpdateDataSourceFromWebsocket,
    mapSceneDataUpdateGeoJSONDataSource,
    mapSceneDataUpdateSearchFeatures,
    mapSceneDataUpdateVectorLayerDataMapSource
} from '../../../scenes/MapScene/actions/reducers/data';
import {mapSceneMapboxLayersActivateDataSource} from '../../../scenes/MapScene/actions/reducers/mapboxLayers';
import {fetchFilterData, loadSearchFeaturesData, loadSourceData} from '../../../services/ViewerApiService';
import {
    viewerApiServiceReceivedSearchFeaturesData
} from '../../../services/ViewerApiService/actions/loadSearchFeaturesData';
import {viewerApiServiceReceivedSourceData} from '../../../services/ViewerApiService/actions/sourceDataData';
import {
    viewerWebsocketServiceMapSourceUpdateMessage,
    viewerWebsocketServiceResetEventMessage
} from '../../../services/ViewerWebsocketService/actions';

export interface LayerUpdateData {
    previousIdentifier: number;
    identifier: number;
}

// Verify the snapshot is an HttpSnapshot (snapshot sent from viewer-common to the PHP backend)
const isHttpSnapshot = (snapshot: HttpSnapshot | StreamableSnapshot | LayerDTO): snapshot is HttpSnapshot => {
    return snapshot?.data &&
        typeof snapshot.data === 'object' &&
        'type' in snapshot.data &&
        snapshot.data.type === 'GeoJsonSnapshotEvent' &&
        snapshot.data.data &&
        Array.isArray(snapshot.data.data.features);
};

const delegateMapLayerDataStorageOnReceivedSourceData: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(viewerApiServiceReceivedSourceData)),
        map(({payload: {data: sourceData, mapSource, dataType}}) => {

            if (dataType === 'base') {
                // if mapSource is streamable, then the response is StreamableSnapshot
                if (mapSource.streamable) {
                    const snapshot: StreamableSnapshot = sourceData as StreamableSnapshot;
                    return mapSceneDataUpdateBaseDataSource({
                        ...snapshot.data,
                        identifier: snapshot.timestamp
                    }, mapSource);
                } else if(isHttpSnapshot(sourceData)){
                    return mapSceneDataUpdateBaseDataSource({
                        ...sourceData.data.data,
                        identifier: sourceData.data.timestamp
                    }, mapSource);
                }

                // The response is a snapshot created in the PHP backend
                const data = sourceData as LayerDTO;
                return mapSceneDataUpdateBaseDataSource(data.data as FeatureCollection & {
                    identifier: number
                }, mapSource);
            } else if (dataType === 'data') {
                return mapSceneDataUpdateVectorLayerDataMapSource((sourceData as LayerDTO).data, mapSource);
            } else if (dataType === 'update') {
                if (mapSource.sourceUpdateIdentifier !== null && mapSource.sourceUpdateIdentifier !== (sourceData.data as LayerUpdateData).previousIdentifier) {
                    return mapSceneDataSourceUpdateMissed(mapSource);
                }

                return mapSceneDataUpdateGeoJSONDataSource(sourceData.data, mapSource);
            } else {
                return EMPTY;
            }
        })
    );

const buildSearchObjectsOnReceivedSourceBaseData: Epic = (action$) =>
    action$
        .pipe(
            filter(isActionOf(mapSceneDataUpdateBaseDataSource)),
            map(({payload: {dataSource, data}}) => {
                const searchFeatures: SearchFeature[] = [];

                if (dataSource.dataLoadMethod === 'GeoJSON') {
                    data.features.forEach((feature) => {
                        const searchValue = isDefinedType(feature, dataSource.searchProperty, ['string', 'value']);
                        const displayValue = isDefinedType(feature, dataSource.searchProperty, ['string', 'value']);
                        let latitude: number | undefined;
                        let longitude: number | undefined;
                        if (feature.geometry.type === 'Point') {
                            latitude = feature.geometry.coordinates[1];
                            longitude = feature.geometry.coordinates[0];
                        } else if (feature.geometry.type === 'LineString') {
                            latitude = feature.geometry.coordinates[0][1];
                            longitude = feature.geometry.coordinates[0][0];
                        }

                        const searchFeature: SearchFeature = {
                            coordinates: {
                                latitude: latitude,
                                longitude: longitude
                            },
                            displayValue: displayValue,
                            id: feature.properties?.id || '',
                            searchValue: searchValue
                        };
                        searchFeatures.push(searchFeature);
                    });
                }

                return mapSceneDataUpdateSearchFeatures(dataSource.id, searchFeatures);
            })
        );

const clearFilteredDataForMapSourceOnClearMapFilteredData: Epic = (action$, state$: StateObservable<RootState>) => action$
    .pipe(
        filter(isActionOf(mapSceneDataClearMapFilteredData)),
        flatMap(() => {
            const mapSourceIDsWithSearchData: string[] = [];

            for (const sourcesKey in state$.value.mapScene.data.sources) {
                if (!state$.value.mapScene.data.sources.hasOwnProperty(sourcesKey)) {
                    continue;
                }

                const source = state$.value.mapScene.data.sources[sourcesKey];

                if (source.isActive || source.layerFilteredMapData) {
                    mapSourceIDsWithSearchData.push(source.id);
                }

            }

            return of(...mapSourceIDsWithSearchData)
                .pipe(
                    map((mapSourceID: string) => mapSceneDataClearFilteredDataForDataSource(mapSourceID))
                );
        })
    );

const loadBaseDataOnActivateMapSource: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(mapSceneMapboxLayersActivateDataSource)),
        filter((action) => action.payload.mapSource.loadBaseData),
        map((action) => mapSceneDataLoadDataSource(action.payload.mapSource))
    );

const loadSourceDataOnLoadDataSource: Epic = (action$, state$: StateObservable<RootState>) => action$
    .pipe(
        filter(isActionOf(mapSceneDataLoadDataSource)),
        mergeMap((action) => loadSourceData(
            action.payload.dataSource,
            action.payload.dataSource.dataLoadMethod === 'GeoJSON' ? 'base' : 'data',
            state$.value.userSettings.theme.colorBlind && action.payload.dataSource.colorBlindSupported
        ))
    );

const loadSourceDataOnUpdateDataSource: Epic = (action$, state$: StateObservable<RootState>) => action$
    .pipe(
        filter(isActionOf(mapSceneDataUpdateDataSource)),
        mergeMap((action) => keycloakService.token()
            .pipe(
                mergeMap(() => loadSourceData(
                    action.payload.dataSource,
                    action.payload.dataSource.dataLoadMethod === 'VectorTiles' ? 'data' : 'update',
                    state$.value.userSettings.theme.colorBlind && action.payload.dataSource.colorBlindSupported
                ))
            ))
    );

const loadBaseGeoJsonOnMapSourceUpdateMissed: Epic = (action$, state$: StateObservable<RootState>) => action$
    .pipe(
        filter(isActionOf(mapSceneDataSourceUpdateMissed)),
        mergeMap((action) => keycloakService.token()
            .pipe(
                mergeMap(() => loadSourceData(
                    action.payload.dataSource,
                    'base',
                    state$.value.userSettings.theme.colorBlind && action.payload.dataSource.colorBlindSupported
                ))
            )
        )
    );

const loadBaseOnResetEvent: Epic = (action$, state$: StateObservable<RootState>) => action$
    .pipe(
        filter(isActionOf(viewerWebsocketServiceResetEventMessage)),
        map(action => mapSceneDataLoadDataSource(state$.value.mapScene.data.sources[action.payload.dataSource]))
    );

const searchFeaturesOnActivateMapSourceVectorTiles: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(mapSceneMapboxLayersActivateDataSource)),
        filter(({payload: {mapSource: {dataLoadMethod}}}) => dataLoadMethod === 'VectorTiles'),
        mergeMap(({payload: {mapSource: {id}}}) => loadSearchFeaturesData(id))
    );

const storeSearchFeaturesInMapSourceOnReceivedMapSourceSearchFeaturesData: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(viewerApiServiceReceivedSearchFeaturesData)),
        map(({payload: {mapSourceId, searchFeatures}}) =>
            mapSceneDataUpdateSearchFeatures(mapSourceId, searchFeatures))
    );

const updateMapSourceDataOnWebsocketReceivedUpdateMessage: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(viewerWebsocketServiceMapSourceUpdateMessage)),
        map(({
            payload: {
                mapSource,
                payload
            }
        }) => mapSceneDataUpdateDataSourceFromWebsocket(mapSource, payload))
    );

const loadFilterData: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(mapSceneMapboxLayersActivateDataSource)),
        mergeMap((action) => fetchFilterData(action.payload.mapLayerToActivate))
    );

const mapSourceEpics: Epic = combineEpics(
    buildSearchObjectsOnReceivedSourceBaseData,
    clearFilteredDataForMapSourceOnClearMapFilteredData,
    loadBaseDataOnActivateMapSource,
    loadSourceDataOnLoadDataSource,
    loadSourceDataOnUpdateDataSource,
    delegateMapLayerDataStorageOnReceivedSourceData,
    loadBaseGeoJsonOnMapSourceUpdateMissed,
    searchFeaturesOnActivateMapSourceVectorTiles,
    storeSearchFeaturesInMapSourceOnReceivedMapSourceSearchFeaturesData,
    loadBaseOnResetEvent,
    updateMapSourceDataOnWebsocketReceivedUpdateMessage,
    loadFilterData
);

export default mapSourceEpics;
