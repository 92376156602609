import {combineEpics, Epic} from 'redux-observable';
import {catchError, filter, map} from 'rxjs/operators';
import {isActionOf} from 'typesafe-actions';
import {
    viewerWebsocketServiceCloseWebsocketConnection,
    viewerWebsocketServiceCreateWebsocketConnection,
    viewerWebsocketServiceHeartbeatMessage,
    viewerWebsocketServiceMapSourceUpdateMessage,
    viewerWebsocketServiceReceivedWebsocketMessage,
    viewerWebsocketServiceResetEventMessage,
    viewerWebsocketServiceUnhandledMessage
} from '../../services/ViewerWebsocketService/actions';
import ViewerWebsocketService from '../../services/ViewerWebsocketService';
import {ViewerWebsocketMessageType} from '../../interfaces/ViewerWebsocket';
import {
    mapSceneDataDeactivateDataSource,
    mapSceneDataLoadDataSource,
    mapSceneDataUpdateBaseDataSource
} from '../../scenes/MapScene/actions/reducers/data';
import {EMPTY, switchMap} from 'rxjs';
import {mapSceneMapboxLayersActivateDataSource} from '../../scenes/MapScene/actions/reducers/mapboxLayers';

const enableWebsocketOnMapSourceOnActiveMapSourceWithWebsocket: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(mapSceneMapboxLayersActivateDataSource)),
        filter(({payload: {mapSource: {websocketReload}}}) => websocketReload),
        map(({
            payload: {
                mapSource: {
                    id,
                    websocketUrl,
                    lastSnapshotUpdateTimestamp,
                    dataStreamerId
                }
            }
        }) => viewerWebsocketServiceCreateWebsocketConnection(id, websocketUrl, lastSnapshotUpdateTimestamp, dataStreamerId))
    );

const reconnectWebsocketOnBaseMapSourceReceived: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(mapSceneDataUpdateBaseDataSource)),
        filter(({payload: {dataSource: {websocketReload}}}) => websocketReload),
        map(({
            payload: {
                data: {
                    identifier
                },
                dataSource: {
                    id,
                    websocketUrl,
                    dataStreamerId
                }
            }
        }) => viewerWebsocketServiceCreateWebsocketConnection(id, websocketUrl, identifier, dataStreamerId!))
    );

const closeWebsocketOnRequestingNewBase: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(mapSceneDataLoadDataSource)),
        filter(({payload: {dataSource: {websocketReload}}}) => websocketReload),
        map(({
            payload: {
                dataSource: {
                    id
                }
            }
        }) => viewerWebsocketServiceCloseWebsocketConnection(id))
    );

const createWebsocketConnection: Epic = (action$) => {
    const websocketClients: { [key: string]: ViewerWebsocketService } = {};

    action$
        .pipe(
            filter(isActionOf(viewerWebsocketServiceCloseWebsocketConnection))
        )
        .subscribe(({payload: {mapSource}}) => {
            websocketClients[mapSource]?.closeWebsocketConnection();
        });

    return action$
        .pipe(
            filter(isActionOf(viewerWebsocketServiceCreateWebsocketConnection)),
            switchMap(({payload: {mapSource, websocketUrl, lastSnapshotUpdateTimestamp, dataStreamerId}}) => {
                if (!websocketClients.hasOwnProperty(mapSource)) {
                    websocketClients[mapSource] = new ViewerWebsocketService(websocketUrl, mapSource);
                }

                const client = websocketClients[mapSource];
                client.connectToWebsocketServer();

                // Keep temporary support of topic messages
                let stream;
                if(dataStreamerId){
                    if (lastSnapshotUpdateTimestamp == undefined) {
                        return EMPTY;
                    }
                    stream = client.getMessages(dataStreamerId, lastSnapshotUpdateTimestamp);
                } else {
                    stream = client.getTopicMessages();
                }
                return stream
                    .pipe(
                        map((message) => {
                            return viewerWebsocketServiceReceivedWebsocketMessage(mapSource, message);
                        }),
                        catchError(err => {
                            // eslint-disable-next-line no-console
                            console.error(err);
                            return EMPTY;
                        })
                    );
            })
        );
};

const processWebsocketMessageOnReceivedWebsocketMessage: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(viewerWebsocketServiceReceivedWebsocketMessage)),
        map(({payload: {mapSource, message}}) => {
            switch (message.type) {
                case ViewerWebsocketMessageType.UPDATE:
                case ViewerWebsocketMessageType.UPDATE_EVENT:
                case ViewerWebsocketMessageType.STREAMING_UPDATE_EVENT:
                    return viewerWebsocketServiceMapSourceUpdateMessage(mapSource, message);
                case ViewerWebsocketMessageType.HEARTBEAT:
                    return viewerWebsocketServiceHeartbeatMessage();
                case ViewerWebsocketMessageType.RESET_EVENT:
                case ViewerWebsocketMessageType.STREAMING_RESET_EVENT:
                    return viewerWebsocketServiceResetEventMessage(mapSource);
                default:
                    return viewerWebsocketServiceUnhandledMessage();
            }
        })
    );

const closeWebsocketConnectionOnDeactivateMapSourceWithWebsocket: Epic = (action$) => action$
    .pipe(
        filter(isActionOf(mapSceneDataDeactivateDataSource)),
        filter(({payload: {dataSource: {websocketReload}}}) => websocketReload),
        map(({payload: {dataSource: {id}}}) => viewerWebsocketServiceCloseWebsocketConnection(id))
    );

const viewerWebsocketServiceEpics: Epic = combineEpics(
    enableWebsocketOnMapSourceOnActiveMapSourceWithWebsocket,
    createWebsocketConnection,
    closeWebsocketConnectionOnDeactivateMapSourceWithWebsocket,
    processWebsocketMessageOnReceivedWebsocketMessage,
    reconnectWebsocketOnBaseMapSourceReceived,
    closeWebsocketOnRequestingNewBase
);

export default viewerWebsocketServiceEpics;
