import {ActionType, createAction} from 'typesafe-actions';
import ViewerWebsocketService from '../index';
import {VIEWER_WEBSOCKET_MESSAGE, ViewerWebsocketUpdateMessage} from '../../../interfaces/ViewerWebsocket';
import {Feature} from 'geojson';

export const viewerWebsocketServiceCreateWebsocketConnection = createAction(
    'VIEWER_WEBSOCKET_SERVICE_CREATE_WEBSOCKET_CONNECTION',
    (action) => (mapSource: string, websocketUrl: string, lastSnapshotUpdateTimestamp: number | null, dataStreamerId: string | null) => action({mapSource, websocketUrl, lastSnapshotUpdateTimestamp, dataStreamerId})
);

export const viewerWebsocketServiceCreatedWebsocketInstance = createAction(
    'VIEWER_WEBSOCKET_SERVICE_CREATED_WEBSOCKET_INSTANCE',
    (action) => (websocketService: ViewerWebsocketService) => action({websocketService})
);

export const viewerWebsocketServiceCloseWebsocketConnection = createAction(
    'VIEWER_WEBSOCKET_SERVICE_CLOSE_WEBSOCKET_CONNECTION',
    (action) => (mapSource: string) => action({mapSource})
);

export const viewerWebsocketServiceReceivedWebsocketMessage = createAction(
    'VIEWER_WEBSOCKET_SERVICE_RECEIVED_WEBSOCKET_MESSAGE',
    (action) => (mapSource: string, message: VIEWER_WEBSOCKET_MESSAGE) => action({mapSource, message})
);

export const viewerWebsocketServiceMapSourceUpdateMessage = createAction(
    'VIEWER_WEBSOCKET_SERVICE_MAP_SOURCE_UPDATE_MESSAGE',
    (action) => (mapSource: string, payload: ViewerWebsocketUpdateMessage<Feature>) => action({mapSource, payload})
);

export const viewerWebsocketServiceHeartbeatMessage = createAction(
    'VIEWER_WEBSOCKET_SERVICE_HEARTBEAT_MESSAGE',
    (action) => () => action({})
);

export const viewerWebsocketServiceResetEventMessage = createAction(
    'VIEWER_WEBSOCKET_SERVICE_RESET_EVENT_MESSAGE',
    (action) => (dataSource: string) => action({dataSource})
);

export const viewerWebsocketServiceUnhandledMessage = createAction(
    'VIEWER_WEBSOCKET_SERVICE_UNHANDLED_MESSAGE',
    (action) => () => action({})
);

export type ViewerWebsocketServerActionTypes = ActionType<typeof viewerWebsocketServiceCreateWebsocketConnection> |
    ActionType<typeof viewerWebsocketServiceCreatedWebsocketInstance> |
    ActionType<typeof viewerWebsocketServiceCloseWebsocketConnection> |
    ActionType<typeof viewerWebsocketServiceReceivedWebsocketMessage> |
    ActionType<typeof viewerWebsocketServiceMapSourceUpdateMessage> |
    ActionType<typeof viewerWebsocketServiceHeartbeatMessage> |
    ActionType<typeof viewerWebsocketServiceResetEventMessage> |
    ActionType<typeof viewerWebsocketServiceUnhandledMessage>;
